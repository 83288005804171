import React, { useEffect, useState } from 'react';
import { useAuth } from '../services/auth';
import axios from 'axios';
import { CircularProgress, Alert, Box, Typography } from '@mui/material';

const Overview = () => {
  const { user } = useAuth();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchServices = async () => {
      if (!user) return;

      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/services`, {
          headers: { Authorization: `Bearer ${user.access_token}` },
        });
        const allServices = response.data;

        // Extract user's tenant ID
        const userTenantId = user['urn:zitadel:iam:user:resourceowner:id'];

        // Map through services to determine the correct URL
        const servicesWithUrls = allServices.map(service => {
          const tenantService = service.Tenants.find(tenant => tenant.organization_id === userTenantId)?.TenantService;
          return {
            ...service,
            url: tenantService?.url || service.default_url // Use tenant-specific URL if available
          };
        });

        setServices(servicesWithUrls);
      } catch (error) {
        console.error('Error fetching services:', error);
        setError('Failed to fetch services.');
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [user, API_URL]);

  const displayIcon = (iconData) => {
    if (!iconData) return null;
    const blob = new Blob([new Uint8Array(iconData.data)], { type: 'image/png' });
    const url = URL.createObjectURL(blob);
    return <img src={url} alt="Service Icon" style={{ width: '50px', height: '50px' }} />;
  };

  const handleServiceClick = (service) => {
    let url = service.url;
    // Add protocol if missing
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }
    window.location.href = url; // Open in the same window
  };

  // Extract username from the user's profile
  const username = user?.profile?.name || 'User';

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Willkommen {username}, hier finden Sie alles Notwendige.
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={2}>
        {services.length > 0 ? (
          services.map(service => (
            <Box
              key={service.id}
              onClick={() => handleServiceClick(service)} // Use the handleServiceClick function
              sx={{
                width: 250,
                height: 150,
                borderRadius: 2,
                boxShadow: 3,
                padding: 2,
                cursor: 'pointer',
                backgroundColor: '#f5f5f5',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
              }}
            >
              {displayIcon(service.iconData)}
              <Typography variant="h6">{service.displayName || service.name || 'N/A'}</Typography>
              <Typography variant="body2">{service.description || 'No description available'}</Typography>
              {/* <Typography variant="body2">{service.visible_to_all ? 'Visible to All' : 'Restricted'}</Typography> */}
            </Box>
          ))
        ) : (
          <Typography>Leider keinen Service für Sie gefunden.</Typography>
        )}
      </Box>
    </div>
  );
};

export default Overview;