// src/components/AdminPanel.js
import React from 'react';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import ServicesTab from './ServicesTab';
import TenantsTab from './TenantsTab';
import RolesTab from './RolesTab';
import TenantServicesTab from './TenantServicesTab';

const AdminPanel = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box>
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Services" />
          <Tab label="Tenants" />
          <Tab label="Roles" />
          <Tab label="Tenant Services" />
        </Tabs>
      </AppBar>

      <Box p={2}>
        {tabIndex === 0 && <ServicesTab />} 
        {tabIndex === 1 && <TenantsTab />}
        {tabIndex === 2 && <RolesTab />}
        {tabIndex === 3 && <TenantServicesTab />}
      </Box>
    </Box>
  );
};

export default AdminPanel;