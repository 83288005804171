// src/components/TenantsTab.js
import React, { useEffect, useState, useMemo } from 'react';
import { useAuth } from '../services/auth';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TenantsTab = () => {
  const { user } = useAuth();
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentTenant, setCurrentTenant] = useState({ name: '', organization_id: '' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTenants = async () => {
      if (!user) return;

      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/tenants`, {
          headers: { Authorization: `Bearer ${user.access_token}` },
        });
        setTenants(response.data);
      } catch (error) {
        console.error('Error fetching tenants:', error);
        setError('Failed to fetch tenants.');
      } finally {
        setLoading(false);
      }
    };

    fetchTenants();
  }, [user, API_URL]);

  const handleAdd = () => {
    setCurrentTenant({ name: '', organization_id: '' });
    setDialogOpen(true);
  };

  const handleEdit = (tenant) => {
    setCurrentTenant(tenant);
    setDialogOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/api/tenants/${id}`, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });
      setTenants(tenants.filter(tenant => tenant.id !== id));
    } catch (error) {
      console.error('Error deleting tenant:', error);
      setError('Failed to delete tenant.');
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSave = async () => {
    try {
      if (currentTenant.id) {
        await axios.put(`${API_URL}/api/tenants/${currentTenant.id}`, currentTenant, {
          headers: { Authorization: `Bearer ${user.access_token}` },
        });
        setTenants(tenants.map(tenant => (tenant.id === currentTenant.id ? currentTenant : tenant)));
      } else {
        const response = await axios.post(`${API_URL}/api/tenants`, currentTenant, {
          headers: { Authorization: `Bearer ${user.access_token}` },
        });
        setTenants([...tenants, response.data]);
      }
      setDialogOpen(false);
    } catch (error) {
      console.error('Error saving tenant:', error);
      setError('Failed to save tenant.');
    }
  };

  const handleMenuOpen = (event, tenant) => {
    setAnchorEl(event.currentTarget);
    setSelectedTenant(tenant);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTenant(null);
  };

  const tableRows = useMemo(() => {
    return tenants.map(tenant => (
      <TableRow key={tenant.id}>
        <TableCell>{tenant.name || 'N/A'}</TableCell>
        <TableCell>{tenant.organization_id || 'N/A'}</TableCell>
        <TableCell>
          <IconButton onClick={(event) => handleMenuOpen(event, tenant)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => { handleEdit(selectedTenant); handleMenuClose(); }}>Edit</MenuItem>
            <MenuItem onClick={() => { handleDelete(selectedTenant.id); handleMenuClose(); }}>Delete</MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    ));
  }, [tenants, anchorEl, selectedTenant]);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <div>
      <h2>Tenants</h2>
      <Button variant="contained" onClick={handleAdd}>Add Tenant</Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Organization ID</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants.length > 0 ? tableRows : (
              <TableRow>
                <TableCell colSpan={3}>No tenants found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{currentTenant.id ? 'Edit Tenant' : 'Add Tenant'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={currentTenant.name}
            onChange={(e) => setCurrentTenant({ ...currentTenant, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Organization ID"
            type="text"
            fullWidth
            value={currentTenant.organization_id}
            onChange={(e) => setCurrentTenant({ ...currentTenant, organization_id: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TenantsTab;