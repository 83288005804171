import React from 'react';
import { useAuth } from '../services/auth';
import { Button, Container, Typography, CircularProgress, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { Navigate } from 'react-router-dom';

function Login() {
  const { login, isAuthenticated, loading, error, clearError } = useAuth();

  const handleLogin = async () => {
    await login();
  };

  if (loading) {
    return (
      <Container maxWidth="sm" style={{ marginTop: '2rem', textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (isAuthenticated) {
    return <Navigate to="/overview" />;
  }

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Welcome to Buildlinx-Services
      </Typography>
      <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
        Login 
      </Button>
      <Snackbar open={!!error} onClose={clearError}>
        <Alert onClose={clearError} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Login;