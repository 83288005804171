import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function Unauthorized() {
  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Unauthorized Access
      </Typography>
      <Typography variant="body1" paragraph>
        You do not have permission to access this page.
      </Typography>
      <Button component={Link} to="/overview" variant="contained" color="primary">
        Go to Overview
      </Button>
    </Container>
  );
}

export default Unauthorized;