import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../services/auth';
import { Avatar, Menu, MenuItem, IconButton } from '@mui/material';

const Navigation = () => {
  const { user, roles, logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  const getInitials = (name) => {
    const initials = name.split(' ').map(part => part[0]).join('');
    return initials.toUpperCase();
  };

  const profilePicture = user?.profile?.picture;
  const userName = user?.profile?.name || 'User';

  const hasAdminAccess = roles.includes('buildlinx-admin');

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      background: 'linear-gradient(90deg, #007BFF, #6A0DAD)', // Gradient from blue to purple
      color: 'white',
    }}>
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit', fontSize: '24px', marginRight: 'auto' }}>
        Buildlinx
      </Link>
      {hasAdminAccess && (
        <Link to="/admin" style={{ textDecoration: 'none', color: 'inherit', marginRight: '20px' }}>
          Admin Interface
        </Link>
      )}
      <IconButton onClick={handleMenuOpen}>
        <Avatar src={profilePicture} alt={userName}>
          {!profilePicture && getInitials(userName)}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>Edit Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default Navigation;