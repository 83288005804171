import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isFetchingUser = useRef(false);

  const userManager = new UserManager({
    authority: process.env.REACT_APP_ZITADEL_ISSUER,
    client_id: process.env.REACT_APP_ZITADEL_CLIENT_ID,
    redirect_uri: `${window.location.origin}/callback`,
    silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
    response_type: 'code',
    scope: 'openid profile email urn:zitadel:iam:org:project:roles urn:zitadel:iam:user:resourceowner',
    post_logout_redirect_uri: window.location.origin,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
  });

  const isTokenExpired = (token) => {
    if (!token) return true;
    const { exp } = jwtDecode(token);
    return Date.now() >= exp * 1000;
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (isFetchingUser.current) return;
      isFetchingUser.current = true;

      try {
        // Introduce a slight delay to allow any pending processes to complete
        setTimeout(async () => {
          const user = await userManager.getUser();
          if (user && !isTokenExpired(user.access_token)) {
            // console.log('User authenticated:', user);
            setUser(user);
            extractRoles(user);
          } else {
            console.log('No valid user found.');
          }
        }, 500); // Adjust the delay as needed
      } catch (err) {
        console.error("Failed to get user:", err);
        setError("Failed to initialize authentication. Please try again later.");
      } finally {
        setLoading(false);
        isFetchingUser.current = false;
      }
    };

    fetchUser();

    userManager.events.addUserLoaded((user) => {
      // console.log('User loaded (silent renew success):', user);
      setUser(user);
    });

    userManager.events.addSilentRenewError((error) => {
      console.error('Silent renew error:', error);
      setError('Silent renew failed. Please log in again.');
    });

    return () => {
      userManager.events.removeUserLoaded();
      userManager.events.removeSilentRenewError();
    };
  }, []);

  const extractRoles = (user) => {
    const rolesClaim = user.profile['urn:zitadel:iam:org:project:roles'];
    // console.log("RolesClaim: ", rolesClaim); 
  
    if (Array.isArray(rolesClaim)) {
      // Extract role names from each object in the rolesClaim array
      const extractedRoles = rolesClaim.flatMap(roleObj => Object.keys(roleObj));
      const uniqueRoles = [...new Set(extractedRoles)];
      if (JSON.stringify(uniqueRoles) !== JSON.stringify(roles)) {
        setRoles(uniqueRoles);
        // console.log("Extracted roles:", uniqueRoles);
      }
    }
  };

  const login = async () => {
    try {
      await userManager.signinRedirect();
    } catch (err) {
      console.error("Login failed:", err);
      setError("Login failed. The authentication service might be unavailable. Please try again later.");
    }
  };

  const handleCallback = async () => {
    try {
      // console.log("Handling callback...");
      const user = await userManager.signinRedirectCallback();
      setUser(user);
      extractRoles(user);
    } catch (err) {
      console.error("Callback handling failed:", err);
      setError("Authentication callback failed. Please try again later.");
    }
  };

  const logout = async () => {
    try {
      await userManager.signoutRedirect();
    } catch (err) {
      console.error("Logout failed:", err);
      setError("Logout failed. Please try again later.");
    }
  };

  const clearError = () => setError(null);

  const value = {
    user,
    roles,
    login,
    logout,
    loading,
    error,
    clearError,
    isAuthenticated: !!user,
    handleCallback,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}