// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../services/auth';

function PrivateRoute({ children, requiredRole }) {
  const { isAuthenticated, roles, login, loading } = useAuth();

  if (loading) {
    // Optionally, return a loading spinner or message
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    login(); // Initiate login if not authenticated
    return null; // Prevent rendering until login is complete
  }

  if (requiredRole && !roles.includes(requiredRole)) {
    console.log("Roles loaded:" , roles);
    return <Navigate to="/unauthorized" />;
  }

  return children;
}

export default PrivateRoute;