// src/pages/Callback.js
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../services/auth';
import { CircularProgress, Container } from '@mui/material';

function Callback() {
  const { handleCallback } = useAuth();
  const navigate = useNavigate();
  const hasHandledCallback = useRef(false);

  useEffect(() => {
    const processCallback = async () => {
      if (hasHandledCallback.current) return; // Prevent multiple executions
      hasHandledCallback.current = true;

      try {
        await handleCallback();
        navigate('/overview');
      } catch (error) {
        console.error('Callback processing error:', error);
        navigate('/login');
      }
    };

    processCallback();
  }, [handleCallback, navigate]);

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem', textAlign: 'center' }}>
      <CircularProgress />
      <p>Processing login, please wait...</p>
    </Container>
  );
}

export default Callback;